.cancelBtn {
  position: absolute;
  right: 3px;
  top: 3px;
  font-size: 11px;
  background: #cfd0d7;
  border-radius: 50%;
  padding: 1px 5px;
  color: #1d2341;
  font-weight: 700;
  cursor: pointer;
}