.primaryBtn {
  background-color: #f7f7fa;
  border: 1px solid #51bd88;
  box-shadow: 0px 4px 20px rgba(black, 0.12);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #51bd88;
  padding: 8px 24px;
  text-align: center;
  vertical-align: middle;
  line-height: 1.5;
}

.primaryBtn:hover,
.primaryBtn:focus {
  color: white;
  background-color: #51bd88;
  box-shadow: none;
  outline: 0;
}

.primaryBtn:disabled {
  opacity: 0.5;
}