.cardWrapper {
  background: #fff;
  padding: 20px 24px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  position: relative;
}

.header {
  color: #25396f;
  font-weight: 700;
  font-size: 20px;
}

.rowWrapper {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

.addBtn {
  margin-right: 15px;
}

.label {
  font-size: 14px;
  font-weight: 600 !important;
  color: #5f6377;
  font-weight: 400;
  padding-left: 1px;
  pointer-events: none;
  margin-bottom: 5px;
}

.inputStyle {
  margin-bottom: 10px;
  border: 1px solid #cfd0d7;
  padding: 11px 12px;
  border-radius: 2px;
  height: 42px;
  width: 100%;
}

.inputWrapper {
  margin: 5px 0;
}

.imageWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.imageBox {
  width: 80px;
  height: 80px;
  border: 1px solid #cfd0d7;
  border-radius: 2px;
  background-color: #fcfcfd;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.imageBox:hover {
  box-shadow: 0 2px 6px rgba(black, 0.1);
}

.imageUploadWrapper {
  box-shadow: 0 4px 20px rgba(black, 0.1);
  position: relative;
}

.imageInput {
  position: relative;
  height: 100%;
  margin: 0;
  opacity: 0;
  z-index: 99;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileLable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  @extend .flexCenter;
  cursor: pointer;
}

.uploadIcon {
  @extend .flexCenter;
  width: 32px;
  height: 32px;
  border: 1px solid rgba(29, 35, 65, 0.3);
  color: rgba(29, 35, 65, 0.3);
  border-radius: 50%;
}

.customBtn,
.customBtn:hover {
  background: #f25cc5;
  border: 2px solid #f25cc5;
  color: white;
  box-shadow: none;
  margin-top: 10px;
}
